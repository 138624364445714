<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar diagnóstico rápido', v-model='search', hide-details)
            v-spacer
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Diagnósticos ativos' : 'Diagnósticos inativos'", v-model="filter.ativo")
            v-spacer
            //- v-autocomplete(label="Selecione um tipo de questionário", :items="listTiposQuestionarios", :disabled="!listTiposQuestionarios.length", item-text="nome", item-value="id", color="gray", v-model="filter.tipo_questionario_id", :loading="loadingTiposQuestionarios", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'").mt-3
            v-spacer
            v-btn(:to="{ name: 'DiagnosticoRapidoCadastro' }", color="bt-primary").white--text Cadastrar
          v-divider
          v-card-text.pa-2
            v-data-table(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading").elevation-0
              template(slot='items', slot-scope='props')
                td(width="5%").text-xs-center {{ props.item.id }}
                td.text-xs-left {{ props.item.nome }}
                td(width="15%").text-xs-center
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, :to="{ name: 'DiagnosticoRapidoEditar', params: { id: props.item.id } }")
                      v-icon edit
                    span Editar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}

    //- v-dialog(v-model="dialogQuestionario", width="auto", scrollable, max-width="800px", persistent, v-if="dialogQuestionario")
    //-   v-card
    //-     form(@submit.prevent="salvar('questionario')", data-vv-name="solicitacaoLicenca")
    //-       v-card-title
    //-         span.headline Cadastrar Diagnóstico Rápido
    //-         v-spacer
    //-         v-tooltip(top)
    //-           v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogQuestionario = false; questionarioAux = {}")
    //-             v-icon close
    //-           span Fechar
    //-       v-divider
    //-       v-card-text
    //-         v-layout(row, wrap).pa-2
    //-           v-flex.md12.xs12.pa-1
    //-             v-text-field(label="Nome", color="primary", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="Nome", type="text", v-model='questionarioAux.nome')
    //-       v-divider
    //-       v-card-actions.headline.grey.lighten-2
    //-         v-layout(row, wrap).pa-1
    //-           v-flex.xs12.text-xs-right
    //-             v-btn(color="black", flat, @click.native="dialogQuestionario = false; questionarioAux = {}") Fechar
    //-             v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  data: () => ({
    filter: { ativo: 1 },
    loadingTiposQuestionarios: false,
    questionarios: [],
    dialogQuestionario: false,
    questionarioAux: {},
    listTiposQuestionarios: [],
    headers: [
      { text: '#', align: 'center', value: 'id' },
      { text: 'Nome', align: 'left', value: 'nome' },
      { text: 'Ações', align: 'center', sortable: false },
    ],
    search: '',
    loading: false
  }),
  computed: {
    list () {
      const vm = this;
      let questionarios = vm.questionarios;
      if (!_.isNil(this.filter.tipo_questionario_id)) {
        questionarios = _.filter(questionarios, { tipo_questionario_id : vm.filter.tipo_questionario_id });
      } else if (!_.isNil(this.filter.ativo)) {
        questionarios = _.filter(questionarios, { ativo: this.filter.ativo });
      }
      return questionarios;
    }
  },
  mounted () {
    const vm = this;
    vm.getQuestionarios();
  },
  methods: {
    async save (questionario) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/questionario-dr', questionario);
        questionario.id = response.data.id;
        vm.$router.push({ name: 'DiagnosticoRapidoEditar', params: { id: response.data.id } });
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Diagnóstico Rápido salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o questionário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        const result = await vm.$validator.validateAll(scope);
        if (!result) throw 'Preencha todos os campos corretamente!';
        if (scope === 'questionario') {
          vm['save'](vm.questionarioAux);
        }
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getQuestionarios () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/questionario-dr');
        vm.questionarios = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async changeAtivo (questionario) {
      const vm = this;
      try {
        const obj = _.cloneDeep(questionario);
        obj.ativo = obj.ativo ? 0 : 1;
        const response = await vm.$axios.put(`/questionario-dr/${obj.id}`, obj);
        questionario.ativo = response.data.ativo;
        window.getApp.$emit('APP_ALERT', { color: obj.ativo ? 'success' : 'orange', text: `Diagnóstico Rápido ${questionario.ativo ? 'ativado' : 'desativado'} com sucesso!` });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o questionário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async remove (questionario) {
      const vm = this;
      try {
        const res = await vm.$confirm('Deseja realmente excluir?<br> Essa ação não poderá ser desfeita!');
        if (!res) return;
        const response = await vm.$axios.delete(`/questionario-dr/${questionario.id}`);
        vm.questionarios = _.reject(vm.questionarios, { id: questionario.id });
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Diagnóstico Rápido excluído com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao excluir o questionário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async openDialogQuestionario() {
      const vm = this;
      vm.dialogQuestionario = true;
    },
    customFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(item.nome || item.texto);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    }
  }
};
</script>

<style scoped>

</style>

